import React, {useState} from 'react';
import './App.css';

import SigNeuvo from './SigNeuvo';
import Header from './Header';
import MemberButton from './MemberButton';

const team = [
  {
    name: "Eaaammoon",
    fullName: "Aemon Beech",
    title: "Creative Director",
    mobile: "0401 905 213",
    image:
      "https://res.cloudinary.com/dg7kooel1/image/upload/v1715922209/email-sig/nnu44epmssnmj7wgntec.png",
    image2x:
      "https://res.cloudinary.com/dg7kooel1/image/upload/v1716186518/email-sig/jqiq2ockbxfq86isjet5.png",
  },
  {
    name: "Chel",
    fullName: "Chelsea Beech",
    title: "Founder & Managing Director",
    mobile: "0413 577 631",
    image:
      "https://res.cloudinary.com/dg7kooel1/image/upload/v1715922209/email-sig/zwmgg8rzzbn5jlx4mdi8.png",
    image2x:
      "https://res.cloudinary.com/dg7kooel1/image/upload/v1716186518/email-sig/imyjkd1ev0z2gshqhyec.png",
  },
  {
    name: "Em",
    fullName: "Emily O'Brien",
    title: "Designer",
    mobile: "",
    image:
      "https://res.cloudinary.com/dg7kooel1/image/upload/v1715922209/email-sig/is6cqhs8z76ysftyubge.png",
    image2x:
      "https://res.cloudinary.com/dg7kooel1/image/upload/v1716186518/email-sig/qeqcqx76jfxwxspk7e1u.png",
  },
  {
    name: "Lach dog",
    fullName: "Lachlan 'The Shit' Sarv",
    title: "Sexiest Man Alive / Mr Olympia / Design Prodigy",
    mobile: "",
    image:
      "https://res.cloudinary.com/dg7kooel1/image/upload/v1715922209/email-sig/flzofgj8hpomwsna3qaa.png",
    image2x:
      "https://res.cloudinary.com/dg7kooel1/image/upload/v1716186518/email-sig/engtoklhwgq2ngn1q0zk.png",
  },
  {
    name: "Syrah",
    fullName: "Sarah Parry-Jones",
    title: "Senior Account Manager",
    mobile: "0401 958 923",
    image:
      "https://res.cloudinary.com/dg7kooel1/image/upload/v1715922210/email-sig/xtcm13zsioi1s6g4gfgd.png",
    image2x:
      "https://res.cloudinary.com/dg7kooel1/image/upload/v1716186519/email-sig/b01hagd6y9fqvwdvpqxs.png",
  },
  {
    name: "Carollo",
    fullName: "Carol Pearson",
    title: "Studio Manager",
    mobile: "0411 149 460",
    image:
      "https://res.cloudinary.com/dg7kooel1/image/upload/v1715922209/email-sig/ptg1gfmlvvtcv54aojgk.png",
    image2x:
      "https://res.cloudinary.com/dg7kooel1/image/upload/v1716186518/email-sig/wc96syelcdowtr8g92pk.png",
  },
  {
    name: "J",
    fullName: "Josh Wayman",
    title: "Head of Strategy & Digital",
    mobile: "0405 451 035",
    image:
      "https://res.cloudinary.com/dg7kooel1/image/upload/v1715922209/email-sig/elqaifb5fd36p0j8qknq.png",
    image2x:
      "https://res.cloudinary.com/dg7kooel1/image/upload/v1716186518/email-sig/vw1nj7hw5merbbsnywq4.png",
  },
];

const colors = [
  { name: "Rorange", hex: "#FF5D36" },
  { name: "Pink", hex: "#F295CE" },
  { name: "Blue", hex: "#0089D8" },
  { name: "Green", hex: "#00AF9B" },
  { name: "Yellow", hex: "#FFD140" },
  { name: "Black", hex: "#111414" },
  { name: "Gray", hex: "#747474" }
];

function App() {
  const [name, setName] = useState('Eaaammon Bech');
  const [title, setTitle] = useState('Boos of Things');
  const [mobile, setMobile] = useState("04 9123 3213");
  const [email, setEmail] = useState('hi@beech.agency');
  const [img, setImg] = useState(
    "https://res.cloudinary.com/dg7kooel1/image/upload/v1715922209/email-sig/nnu44epmssnmj7wgntec.png"
  );
  const [lineColor, setLineColor] = useState("#FF5D36");

  const [website, setWebsite] = useState('beech.agency');
  const [fullURL, setFullURL] = useState('https://beech.agency');
  const [officePhone, setOfficePhone] = useState('04 9123 3213');
  const [address, setAddress] = useState('Level 2, 21 Bolton St, Newcastle NSW');

  const handleMemberButtonPreset = ( member ) => {
    setName(member.fullName);
    setTitle(member.title);
    setMobile(member.mobile);
    setImg(member.image2x);
  }

  return (
    <div className="App">
      <Header />
      <section className="people-presets">
        <h4>Presets</h4>
        <div className="member-button-wrap">
          {team.map((member) => {
            return (
              <MemberButton
                member={member}
                handleOnClick={handleMemberButtonPreset}
              />
            );
          })}
        </div>
      </section>
      <header className="App-header">
        <form className="form-container">
          <div className="inputGroup">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="inputGroup">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              name="name"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
          {/*
          <div className="inputGroup">
            <label htmlFor="officePhone">Office Phone</label>
            <input
              type="text"
              name="officePhone"
              value={officePhone}
              onChange={(e) => {
                setOfficePhone(e.target.value);
              }}
            />
          </div>
            */}
          <div className="inputGroup">
            <label htmlFor="mobile">Phone</label>
            <input
              type="text"
              name="mobile"
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
            />
          </div>
          {/*
          <div className='inputGroup'>
            <label htmlFor='email'>Email</label>
            <input type='text' 
                name='email' 
                value={email} 
                onChange={ (e) => { setEmail(e.target.value) }} />
          </div>
          <div className='inputGroup'>
            <label htmlFor='website'>Website (Display)</label>
            <input type='text' 
                name='website' 
                value={website} 
                onChange={ (e) => { setWebsite(e.target.value) }} />
          </div>
          <div className='inputGroup'>
            <label htmlFor='fullURL'>Full URL (the link)</label>
            <input type='text' 
                name='fullURL' 
                value={fullURL} 
                onChange={ (e) => { setFullURL(e.target.value) }} />
          </div>
          */}
          <div className="inputGroup">
            <label htmlFor="address">Address</label>
            <textarea
              type="text"
              name="address"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              rows="2"
            />
          </div>
          <div className="inputGroup">
            <label htmlFor="img">Face</label>
            <input
              type="text"
              name="img"
              value={img}
              onChange={(e) => {
                setImg(e.target.value);
              }}
            />
            {/*
            <select
              type="text"
              name="img"
              value={img}
              onChange={(e) => {
                setImg(e.target.value);
              }}
            >
              {team.map((member) => {
                return (
                  <option value={member.image2x} data-image-1x={member.image}>
                    {member.name}
                  </option>
                );
              })}
            </select>
            */}
          </div>
          <div className="inputGroup">
            <label htmlFor="lineColor">Line</label>
            <select
              name="lineColor"
              value={lineColor}
              onChange={(e) => {
                setLineColor(e.target.value);
              }}
            >
              {colors.map(({ name, hex }) => {
                return <option value={hex}>{name}</option>;
              })}
            </select>
          </div>
          <p>Use it wisely.</p>
        </form>
        <div className="sigTemplateContainer">
          <div className="sig-container">
            <SigNeuvo
              name={name}
              title={title}
              mobile={mobile}
              email={email}
              img={img}
              color={lineColor}
              website={website}
              fullURL={fullURL}
              officePhone={officePhone}
              address={address}
            />
          </div>
          {/*
          <div className="sig-container">
            <SigTemplateSmall
              name={name}
              title={title}
              mobile={mobile}
              email={email}
              img={img}
              lineColor={lineColor}
            />
          </div>
            */}
        </div>
      </header>
    </div>
  );
}

export default App;
