import React from 'react'

export default function SigNeuvo({
  name = "Yolo Swaggins",
  title = "Head of the Things",
  mobile = '0400 000 000',
  email = '',
  img = "https://beech.agency/wp-content/uploads/2024/04/Josh_W.jpg",
  color = "#FF5D36",
  address = ''
}) {

  let addressPadding = address === "" ? "10px 0px 5px" : '10px 0px 0px';
  console.log(addressPadding, address, address !== '');

  return (
    <table
      cellPadding={0}
      cellSpacing={0}
      border={0}
      role="presentation"
      className="wrapper"
      width={250}
      data-v-2b014496=""
      style={{
        margin: 0,
        borderSpacing: "0px",
        borderCollapse: "collapse",
      }}
    >
      <tbody>
        <tr>
          <td valign="top" style={{ padding: "0px 0px 10px" }}>
            <table
              cellPadding={0}
              cellSpacing={0}
              border={0}
              role="presentation"
              className="wrapper"
              width="auto"
              style={{
                margin: 0,
                borderSpacing: "0px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ padding: "0px" }}>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      border={0}
                      role="presentation"
                      style={{
                        margin: 0,
                        borderCollapse: "collapse",
                        borderSpacing: "0px",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td>
                            <img
                              width={70}
                              src={img}
                              border={0}
                              alt={`${name} - ${title} / Beech Agency`}
                              style={{
                                width: 68,
                                borderRadius: 100,
                                display: "block",
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "0px" }}>
            <table
              cellPadding={0}
              cellSpacing={0}
              border={0}
              role="presentation"
              className="wrapper"
              width="100%"
              style={{
                margin: 0,
                borderSpacing: "0px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr style={{ fontWeight: 600 }}>
                  <td valign="top" style={{ padding: "0px", fontSize: 0 }}>
                    <p
                      style={{
                        fontSize: 14,
                        fontFamily: "Arial, Helvetica, sans-serif",
                        margin: 0,
                        lineHeight: "150%",
                        display: "block",
                      }}
                    >
                      <span style={{ color: color, fontWeight: 600 }}>
                        {name}
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    valign="top"
                    style={{ padding: "0px 0px 5px", fontSize: 0 }}
                  >
                    <p
                      style={{
                        fontSize: 12,
                        fontFamily: "Arial, Helvetica, sans-serif",
                        margin: 0,
                        lineHeight: "150%",
                      }}
                    >
                      <span>
                        <span style={{ color: "rgb(0, 0, 0)" }}>{title}</span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    valign="top"
                    style={{
                      padding: addressPadding,
                      fontSize: 0,
                    }}
                  >
                    <p
                      style={{
                        fontSize: 12,
                        fontFamily: "Arial, Helvetica, sans-serif",
                        margin: 0,
                        lineHeight: "150%",
                      }}
                    >
                      <span>
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <a
                            href={`tel:${mobile}`}
                            style={{
                              color: "rgb(0, 0, 0)",
                              textDecoration: "none",
                            }}
                          >
                            {mobile}
                          </a>
                        </span>
                      </span>
                      <span>
                        <span
                          style={{ padding: 0, color: "rgb(116, 116, 116)" }}
                        >
                          &nbsp;&nbsp;/&nbsp;&nbsp;
                        </span>
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                          <a
                            href="https://beech.agency"
                            style={{ color: color }}
                          >
                            beech.agency
                          </a>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                {address ? (
                  <tr>
                    <td
                      valign="top"
                      style={{ padding: "0px 0px 5px", fontSize: 0 }}
                    >
                      <p
                        style={{
                          fontSize: 12,
                          fontFamily: "Arial, Helvetica, sans-serif",
                          margin: 0,
                          lineHeight: "150%",
                        }}
                      >
                        <span>
                          <span style={{ color: "rgb(0, 0, 0)" }}>
                            {address}
                          </span>
                        </span>
                      </p>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "0px" }}>
            <table
              cellPadding={0}
              cellSpacing={0}
              border={0}
              role="presentation"
              className=""
              width={300}
              style={{
                margin: 0,
                borderSpacing: "0px",
                borderCollapse: "separate",
                padding: "0px 0px 10px 0px",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ padding: "0px" }}>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      role="presentation"
                      style={{ fontSize: 0, borderCollapse: "collapse" }}
                    >
                      <tbody>
                        <tr>
                          <td
                            align="left"
                            style={{ padding: "10px 0px 0px 0px" }}
                          >
                            <p style={{ margin: 0, borderRadius: 0 }}>
                              <a
                                href="https://beech.agency"
                                style={{
                                  display: "inline-block",
                                  borderRadius: 0,
                                }}
                              >
                                <img
                                  width={130}
                                  height={15}
                                  src="https://res.cloudinary.com/dg7kooel1/image/upload/v1715925073/email-sig/ii5eb9c3plyrqggiryn1.png"
                                  alt="Beech Agency Logo"
                                  style={{
                                    display: "table-cell",
                                    verticalAlign: "middle",
                                    borderRadius: 0,
                                  }}
                                />
                              </a>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
