import React from 'react'

export default function MemberButton({ member, handleOnClick }) {
    return (
        <button className='member-button' onClick={ () => {
            handleOnClick(member)
        }}> { member.name } 
        </button>
    )
}
